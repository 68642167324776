import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import Logo from "../Assets/Images/WhatsApp Image 2024-09-01 at 1.49.06 PM.jpeg";
import axios from "axios";
import Swal from "sweetalert2";

function Partner() {
    const [partners, setPartners] = useState();
    const getPartners = async () => {
        try {
        const response = await axios.get(
          "https://dentotools.addictaco.com/api/v1/partners",
          {
            headers: {
              Accept: ["application/json", "application/json"],
              "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
              "Accept-Language": "en",
              "Content-Type": "application/json",
            },
          }
        );
        setPartners(response.data.data);
        } catch (error) {
        Swal.fire({
            icon: "error",
            title: error.response.data.message,
            text: "Something went wrong!",
        });
        }
    };

    useEffect(() => {
        getPartners();
    }, []);

  return (
    <div className="partner">
      <div className="container">
        <h2 className="main-heading en">OUR PARTNERS</h2>
        <p className="main-description en">
          Lorem ipsum dolor sit amet consectetur nunc nunc sit velit eget
          sollicitudin sit posuere augue vestibulum eget turpis lobortis donec
          sapien integer.
        </p>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          modules={[Autoplay, Navigation]}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }}
          breakpoints={{
            440: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          className="mySwiper"
        >
          {partners?.map((x, index) => (
            <SwiperSlide key={index}>
              <div className="lab-logo" id={x.id}>
                <img src={x.image} alt="lab-logo" />
                <h3>{x.name}</h3>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-buttons">
          <div className="swiper-prev">
            <i className="fa fa-chevron-left"></i>
          </div>
          <div className="swiper-next">
            <i className="fa fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner;
