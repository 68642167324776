import React, { useEffect, useLayoutEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "../Assets/Css/Footer.css";
import Logo from "../Assets/Images/footer-logo.svg";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import axios from "axios";
import Swal from "sweetalert2";

function Footer() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  let [social, setSocial] = useState([]);
  const getSocial = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/social-media`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setSocial(response?.data?.data);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };

  useLayoutEffect(() => {
    getSocial();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading spinner or message
  }
  return (
    <footer className="en">
      <div className="container">
        <div className="footer">
          <div className="footer-logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="footer-links">
            <ul>
              <li className="">
                <NavLink to="/" className="nav-link fromRight">
                  {t("home")}
                </NavLink>
              </li>
              <li className="">
                <NavLink to="/About" className="nav-link">
                  {t("about_nav")}
                </NavLink>
              </li>
              <li className="">
                <NavLink to="/Services" className="nav-link fromRight">
                  {t("Services")}
                </NavLink>
              </li>
              <li className="">
                <NavLink to="/Lab" className="nav-link">
                  {t("Lab")}
                </NavLink>
              </li>
              <li className=" ">
                <NavLink to="/Contact" className="nav-link">
                  {t("contact")}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-social">
            <ul>
              {social.length > 0 ? (
                social.map((x, index) => (
                  <li key={index}>
                    <a href={x.url} target="_blank">
                      <i className={`fab fa-${x.name}`}></i>
                    </a>
                  </li>
                ))
              ) : (
                <p>No social available</p>
              )}
            </ul>
          </div>
        </div>
        <div className="copyRight">
          <p>
            {t("Copyright")}
            <Link to="/Terms" className="ms-2">
              {t("terms")}
            </Link>{" "}
            | <Link to="/Privacy">{t("Privacy")}</Link>
          </p>
          <p>
            {t("Addicta")}{" "}
            <a href="https://addictaco.com/" target="_blank">
              Addicta
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
