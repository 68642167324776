import React, { useEffect, useState } from "react";

function LocationMap({ map }) {
  return (
    <div className="main">
      <div className="location">
        <div className="container">
          <div className="location-card">
            {/* <div>
              <h2>location</h2>
              <p>
                <i className="fa fa-location"></i>11800 Sunrise Valley Dr.,
                Suite #250 Reston, VA 20191
              </p>
              <button>Get Directions</button>
            </div> */}
            <iframe
              src={map}
              height="300"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationMap;
