import React, { useEffect, useState } from "react";
import Labs from "../Data/Labs";
import Logo from "../Assets/Images/WhatsApp Image 2024-09-01 at 1.49.06 PM.jpeg";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Background from '../Assets/Images/509910a362.jpg';

function Lab() {
  let [labs, setLabs] = useState([]);
  let [page, setPage] = useState(-1);
  const getLabs = async () => {
    setPage(++page);
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/labs?page=${page}`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      // setLabs(response.data.data);
      setLabs([...labs, ...response?.data?.data]);
      console.log(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };
  useEffect(() => {
    getLabs();
    // document.body.classList.add('pro-nav');
    document.body.classList.remove("join-nav");
    document.body.classList.add("active-nav");
  }, []);

  return (
    <>
      <div className="main">
        <div className="hero-services" style={{backgroundImage: `url(${Background})`}}><h1>Labs</h1></div>

        <div className="labs en">
          <div className="container">
            <div className="row">
              {labs?.map((x, index) => (
                <div className="col-lg-3 col-md-4" key={index}>
                  <div className="card-lab en">
                    <div className="rate">
                      {x.rate}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                      >
                        <path
                          d="M6.60697 0.756065C6.76774 0.414645 7.23226 0.414645 7.39303 0.756065L9.21661 4.62869C9.28045 4.76427 9.40386 4.85824 9.54661 4.87998L13.6243 5.50099C13.9838 5.55574 14.1273 6.01876 13.8672 6.28452L10.9166 9.29894C10.8133 9.40447 10.7661 9.55652 10.7905 9.70554L11.487 13.962C11.5485 14.3372 11.1727 14.6234 10.8511 14.4462L7.20395 12.4366C7.07627 12.3662 6.92373 12.3662 6.79605 12.4366L3.14889 14.4462C2.82735 14.6234 2.45154 14.3372 2.51295 13.962L3.2095 9.70553C3.23388 9.55652 3.18675 9.40447 3.08345 9.29894L0.132837 6.28452C-0.127295 6.01876 0.0162496 5.55574 0.375744 5.50099L4.45339 4.87998C4.59614 4.85824 4.71955 4.76427 4.78339 4.62869L6.60697 0.756065Z"
                          fill="#EDF051"
                        />
                      </svg>
                    </div>
                    <Link to={`/Labs_details/${x.id}`} id={x.id} className="">
                      <img src={x.banner} alt={x.name} />
                      <div className="p-3">
                        <h2>{x.name}</h2>
                        <p>{x.service_count} Services</p>
                        <p>Delivery Time: {x?.estimated_delivery_time}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}

              {labs &&
              labs.length > 10 && (
                <div className="text-center mt-5">
                  <button className="load-more" onClick={getLabs}>
                    Load more
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="contact en">
          <div className="container">
            <div className="contact-card">
              <div className="contact-layout">
                <h2>
                  For any questions or concerns <br /> don't hesitate to contact
                  us.
                </h2>
                <Link to="/Contact" className="">
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Lab;
