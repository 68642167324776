export const TRANSLATIONS_ar = {
  home: "الرئيسية",
  contact: "تواصل معنا",
  about_nav: "عنا",
  terms: "شروط الاستخدام",
  Privacy: "سياسة خاصة",
  Services: "خدمات",
  NEWS_nav: "الأخبار والمدونة",
  lang: "EN",
  Lab: "معامل",
  Copyright: "حقوق الطبع والنشر © 2024 DENTOTOOLS | كل الحقوق محفوظة",
  Addicta: "طور بشغف بواسطة",
  Join: "انضم إلينا!",
  join_des: "لبدء هذه الرحلة، أخبرنا بنوع الحساب الذي ستفتحه.",
  Register: "تسجيل حساب فردي!",
  Register_des: "لغرض تنظيم الصناعة، التفاصيل الخاصة بك مطلوبة.",
  FullName: "الاسم الكامل",
  FullName_placeholder: "أدخل الاسم الكامل",
  Email: "بريد إلكتروني",
  Email_placeholder: "أدخل عنوان البريد الالكتروني",
  phone: "رقم الهاتف",
  phone_placeholder: "ادخل رقم الهاتف",
  password: "انشاء رمز سري",
  password_placeholder: "ادضل الرمز السري",
  checkbox: "أوافق على الشروط والأحكام",
  Continue: "التالي",
  University: "اسم الجامعة",
  Drag: "قم بالسحب والإفلات هنا",
  back: "السابق",
  Clinic_Address: "عنوان العيادة",
  Syndicate_card: "بطاقة النقابة",
  Clinic_address: "أدخل عنوان العيادة",
  Register: "تسجيل حساب",
  account: "هل لديك حساب؟",
  Sign: "تسجيل الدخول",
  Message: "رسالة",
  Write_message: "اكتب رسالتك..",
  Send_Message: "أرسل رسالة",
};
