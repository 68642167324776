import React, { useEffect, useState } from "react";
import Labs from "../Data/Labs";
import logo from "../Assets/Images/Frame (1).png"
import img from "../Assets/Images/WhatsApp Image 2024-09-01 at 1.49.06 PM.jpeg";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import Swal from "sweetalert2";

function Privacy() {

  return (
    <>
      <div className="main">
        <div className="search-services">
          <h1>Privacy Policy</h1>
        </div>

        <div className="contact en">
          <div className="container">
            <div className="contact-card">
              <div className="contact-layout">
                <h2>
                  For any questions or concerns <br /> don't hesitate to contact
                  us.
                </h2>
                <Link to="/Contact" className="">
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
