import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import img from "../Assets/Images/Frame 1.png";
import "../Assets/Css/login.css";
import { Link } from "react-router-dom";
// import Drag from "../Assets/Images/ant-design_cloud-upload-outlined.svg"
import { Form, Button, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import axios from "axios";
import { axiosConfig } from "./axiosAPI";
import Swal from "sweetalert2";

function SignIn() {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  // const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   navigate(`/Home`);
  //   // handle form submission
  // };

  useEffect(() => {
    // document.body.classList.add('pro-nav');
    document.body.classList.add("join-nav");
  }, []);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const auth = useAuth();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log("fsd")
  //   if (email !== "" && password !== "") {
  //     auth.loginAction({
  //       email: email,
  //       password: password,
  //     });
  //     return;
  //   }
  // };

  const [formData, setFormData] = useState({
    password: "",
    email: "",
  });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const response = await axios
  //     .post(
  //       "https://dentotools.addictaco.com/api/v1/clients/auth/login",
  //       formData,
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
  //           "Accept-Language": "en",
  //           // "device-token":
  //           //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvYXBpL3YxL3RlYWNoZXIvYXV0aC9sb2dpbiIsImlhdCI6MTcxNzA3OTc3NywibmJmIjoxNzE3MDc5Nzc3LCJqdGkiOiJmRWRzUjMyYlNCa2pwTFpEIiwic3ViIjoiM2MxNTI4ODQtODAzMS00Zjk3LWI3MDMtMTljMTY5MDU4ZWFjIiwicHJ2IjoiODFjMGJjYmVlMjMxOGI5MWE4M2U2MDJjOGI5OTRlYmFlMTNmNmY3MyJ9.npevjPT7oGfc-OBItgbtBIQD6FJw5XE5Jx3pXN_l9hk",
  //           "device-type": "Web",
  //           "device-id": "123456",
  //         },
  //         processData: false,
  //         mimeType: "multipart/form-data",
  //         contentType: false,
  //         data: formData,
  //       }
  //     )
  //     .then((res) => {
  //       navigate("/");
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Something went wrong!",
  //       });
  //     });
  // };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      auth.loginAction({
          email: email,
          password: password
      });
      return;
    }
  };

  return (
    <div className="join">
      <div className="join-img">
        <img src={img} alt="" className="" />
      </div>
      <div className="join-div">
        <div className="join-us">
          <h1 className="main-heading">{t("Join")}</h1>
          <p className="main-description">{t("join_des")}</p>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formStep1" className="form-group">
              <Form.Label>{t("Email")}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder={t("Email_placeholder")}
                // value="email"
                // onChange={handleChange}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formStep1" className="form-group">
              <Form.Label>{t("password")}</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder={t("password_placeholder")}
                // value="password"
                onChange={(e) => setPassword(e.target.value)}
                // onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formStep1" className="form-group">
              {/* <Form.Label>Name</Form.Label> */}
              <Form.Check
                type="checkbox"
                defaultChecked
                id="Check"
                label={t("checkbox")}
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <button className="Continue" type="submit" onClick={handleSubmit}>
                Send
              </button>
            </div>
          </Form>
          <h4>
            {t("account")} <Link to="/Register">{t("SignUp")}</Link>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
