import React, { useEffect, useState } from "react";
import img from "../Assets/Images/Frame 1.png"
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import "../Assets/Css/login.css";
import { Link } from "react-router-dom";

function Register() {
    const { t } = useTranslation();

    
    useEffect(() => {
        // document.body.classList.add('pro-nav');
        document.body.classList.add ('join-nav');
    }, []);


    return (
        <div className="join">
            <div className="join-img">
                <img src={img} alt="" className="" />
            </div>
            <div className="join-div">
                <div className="join-us">
                    <h1 className="main-heading">{t("Join")}</h1>
                    <p className="main-description">{t("join_des")}</p>
                    <Link to="/Student" className="student" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="48" viewBox="0 0 50 48" fill="none">
                                <path d="M1.09543 18.7329L25 1.36525L48.9046 18.7329L39.7738 46.8344H10.2262L1.09543 18.7329Z" stroke="#5B6268"/>
                            </svg>
                            <div className="me-4 ms-4">
                                <h3>{t("Student")}</h3>
                                <p>{t("Student_dentistry")}</p>
                            </div>
                            <i className="fa fa-arrow-right go-right"></i>
                    </Link>
                    <Link to="/Clinic" className="student" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="48" viewBox="0 0 50 48" fill="none">
                                <path d="M1.09543 18.7329L25 1.36525L48.9046 18.7329L39.7738 46.8344H10.2262L1.09543 18.7329Z" stroke="#5B6268"/>
                            </svg>
                            <div className="me-4 ms-4">
                                <h3>{t("Clinic")}</h3>
                                <p>{t("Clinic_dentistry")}</p>
                            </div>
                            <i className="fa fa-arrow-right go-right"></i>
                    </Link>
                    <h4>{t("account")} <Link to="/SignIn">{t("Sign")}</Link></h4>
                </div>
            </div>
        </div>
    );
}

export default Register;
