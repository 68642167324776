export const TRANSLATIONS_en = {
  home: "Home",
  Services: "Services",
  contact: "Contact Us",
  about_nav: "About Us",
  terms: "Terms and Conditions",
  Privacy: "Privacy Policy",
  Lab: "Labs",
  Copyright: "Copyright © 2024 DENTOTOOLS | All Rights Reserved",
  Addicta: "Passionately developed by",
  Join: "Join Us!",
  join_des: "To begin this journey, tell us what type of account you’d be opening.",
  Student: "Student",
  Student_dentistry: "Student studying dentistry, this is for you.",
  Clinic: "Clinic ",
  Clinic_dentistry: "Own or belong to a Clinic, this is for you.",
  Register: "Register Individual Account!",
  Register_des: "For the purpose of industry regulation, your details are required.",
  FullName: "Full Name",
  FullName_placeholder: "Enter Full Name",
  Email: "Email",
  Email_placeholder: "Enter email address",
  phone: "Phone",
  phone_placeholder: "Enter phone number",
  password: "password",
  password_placeholder: "Enter Password",
  checkbox: "I agree to terms & conditions",
  Continue: "Continue",
  University: "University Name",
  Drag: "Drag and Drop here",
  back: "back",
  Clinic_Address: "Clinic Address",
  Syndicate_card: "Syndicate card",
  Clinic_address: "Enter Clinic address",
  Register: "Register Account",
  account: "Already have an account?",
  Sign: "Sign In",
  SignUp: "Sign Up",
  Message: "Message",
  Write_message: "Write your message..",
  Send_Message: "Send Message",
  back: "back",
  back: "back",
  back: "back",
  back: "back",
};
