import { format } from 'date-fns';

const TimestampComponent = ({ timestamp }) => {
  const formattedDate = format(new Date(timestamp), 'PP'); // Example: Oct 15, 2024, 12:34 PM

  return (
    <div>
      <span>{formattedDate}</span>
    </div>
  );
};

export default TimestampComponent;