import React, {useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { jwtDecode } from 'jwt-decode';


const PrivateRoute = () => {

  const auth = useAuth();

  // if (!auth.token) return <Navigate to="/SignIn" />;

  // const decodedToken = jwtDecode(auth.token);
  // const isTokenExpired = decodedToken.exp * 10000 < Date.now(); // Multiply by 1000 to convert to milliseconds

  // useEffect(() => {
  //   if (isTokenExpired) {
  //     // Redirect if token is expired
  //     return <Navigate to="/SignIn" />;
  //   }
  // }, [isTokenExpired]);

  return <Outlet />;
};

export default PrivateRoute;
