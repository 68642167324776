import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Img from "../Assets/Images/Successmark.svg"
import axios from "axios";
import Swal from "sweetalert2";
import LocationMap from "./location";
import parse from "html-react-parser";
import { useAuth } from "../logIn/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router";


function Summary() {
    const [show, setShow] = useState(false);
    const [Cancel, setCancel] = useState(false);

    const location = useLocation();
    const [post, setPost] = useState({});
    useEffect(() => {
        setPost(location.state.item);
    }, []);
    const auth = useAuth();
    const navigate = useNavigate();

    console.log(post);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCancelClose = () => setCancel(false);
    const handleCancelShow = () => setCancel(true);


  const CancelOrder = async (data) => {
    try {
      const response = await fetch(
        `https://dentotools.addictaco.com/api/v1/orders/cancel-sterilization-request/${post.id}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        setCancel(false)
        return;
      }
      throw new Error(response.message);
    } catch (err) {
      setCancel(false)
      console.error(err);
    }
  };
  const vatValue = Number(post.vat) || 0; // Fallback to 0 if it's not a valid number
  const formattedVat = vatValue.toFixed(1); 

  
  const [me, setMe] = useState();
  const getMe = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/clients/auth/profile`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            Authorization: "Bearer " + auth.token,
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setMe(response?.data?.data);
      console.log(response);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };
    
    useEffect(() => {
      getMe();
        // document.body.classList.add('pro-nav');
        document.body.classList.add ('active-nav');
    }, []);

  return (
    <div className="main">
      <div className="layout-payment en">
        <div className="container">
          <div className="payment">
            <div className="row">
              <div className="col-md-6">
                <div className="card-payment">
                  <h2>User Details</h2>
                  <div className="d-flex justify-content-between mt-3 mb-3">
                    <h3>
                      <i className="fa fa-user"></i>
                      {me?.name}
                    </h3>
                    <h3>
                      <i className="fa fa-phone"></i> {me?.phone_number}
                    </h3>
                  </div>
                  {me?.user_type == "student" ? (
                    ""
                  ) : (
                    <p>
                      <i className="fa fa-location-dot"></i>
                      {me?.phone_number}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="order-card">
                  <h2>Order Summary</h2>
                  {/* <h3>
                    Order Name <span>49.80 EGP</span>
                  </h3> */}
                  {/* <hr /> */}
                  <h3>
                    Price <span>{post?.price} EGP</span>
                  </h3>
                  <h3>
                    Vat <span>{formattedVat} EGP</span>
                  </h3>
                  <h3>
                    Shipping Cost <span>{post?.shipment_cost} EGP</span>
                  </h3>
                  <hr />
                  <h4>
                    <div>
                      <h3>Total</h3>
                    </div>
                    <span>{post?.total} EGP</span>
                  </h4>
                  <div className="order-buttons">
                    {/* <button className="continue" onClick={handleShow}>
                      Pay {post?.total} EGP
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="success">
              <img src={Img} alt="success" />
              <h2>The order was completed successfully</h2>
              <button className="continue" onClick={handleClose}>
                Check my Orders
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={Cancel} onHide={handleCancelClose}>
          <Modal.Header closeButton></Modal.Header>
          <div className="success">
            <h2>Are you want to cancel the order?</h2>
            <div className="d-flex w-100">
              <button className="back-order" onClick={handleCancelClose}>
                Back
              </button>
              <button className="cancel-order" onClick={CancelOrder}>
                Cancel order
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Summary;
