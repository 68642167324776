import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import img from "../Assets/Images/Frame 1.png"
import "../Assets/Css/login.css";
import { Link } from "react-router-dom";
import Drag from "../Assets/Images/ant-design_cloud-upload-outlined.svg"
import { Form, Button, ProgressBar } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "./axiosAPI";
import Swal from "sweetalert2";

function Clinic() {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
  
    const handleNext = () => {
      setStep(step + 1);
    };
  
    const handlePrevious = () => {
      setStep(step - 1);
    };

    
  const [area, setArea] = useState();
  const getAddress = async () => {
    try {
      const response = await axiosConfig.get(
        "https://dentotools.addictaco.com/api/v1/areas",
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setArea(response.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    password: "",
    university_id: [],
    university_card: [],
    user_type: "clinic",
    agreement: "1",
    area_id: [],
    syndicate_card: [],
    clinic_address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    console.log(formData);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const response = await axios
      .post(
        "https://dentotools.addictaco.com/api/v1/clients/auth/register",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "device-type": "Web",
            "device-id": "123456",
          },
          processData: false,
          mimeType: "multipart/form-data",
          contentType: false,
          data: formData,
        }
      )
      .then((res) => {
        navigate("/SignIn");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          text: "Something went wrong!",
        });
      });
  };


    
    useEffect(() => {
      getAddress()
        // document.body.classList.add('pro-nav');
        document.body.classList.add ('join-nav');
    }, []);


    return (
      <div className="join">
        <div className="join-img">
          <img src={img} alt="" className="" />
        </div>
        <div className="join-div">
          <div className="join-us">
            <Link className="back" to="/Register">
              <i className="fa fa-chevron-left"></i>
              {t("back")}
            </Link>
            <h1 className="main-heading">{t("Register")}</h1>
            <p className="main-description">{t("Register_des")}</p>
            <Form onSubmit={handleRegister}>
              {step === 1 && (
                <>
                  <Form.Group controlId="formStep1" className="form-group">
                    <Form.Label>{t("FullName")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder={t("FullName_placeholder")}
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formStep1" className="form-group">
                    <Form.Label>{t("Email")}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={t("Email_placeholder")}
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formStep1" className="form-group">
                    <Form.Label>{t("phone")}</Form.Label>
                    <Form.Control
                      type="number"
                      name="phone_number"
                      placeholder={t("phone_placeholder")}
                      value={formData.phone_number}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formStep1" className="form-group">
                    <Form.Label>{t("password")}</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder={t("password_placeholder")}
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formStep1" className="form-group">
                    {/* <Form.Label>Name</Form.Label> */}
                    <Form.Check
                      type="checkbox"
                      defaultChecked
                      id="Check"
                      label={t("checkbox")}
                    />
                  </Form.Group>
                </>
              )}
              {step === 2 && (
                <>
                <Form.Group controlId="formStep2" className="form-group">
                  <Form.Label>Address Area</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="area_id"
                    onChange={handleChange}
                  >
                    <option>Select Area</option>
                    {area?.map((x, index) => (
                      <option value={x.id} key={index}>
                        {x.area}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                  <Form.Group controlId="formStep1" className="form-group">
                    <Form.Label>{t("Clinic Address")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="clinic_address"
                      placeholder={t("Clinic Address")}
                      value={formData.clinic_address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formStep2" className="form-group">
                    <Form.Label>{t("Syndicate_card")}</Form.Label>
                    <Form.Control
                      className="file"
                      type="file"
                      name="syndicate_card"
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </>
              )}
              <div className="d-flex justify-content-between">
                {step < 2 ? (
                  <a href="#!" className="Continue" onClick={handleNext}>
                    {t("Continue")}
                  </a>
                ) : (
                  <button className="Continue" type="submit">
                    {t("Register")}
                  </button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
}

export default Clinic;
