import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Img from "../Assets/Images/Successmark.svg"
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import LocationMap from "./location";
import parse from "html-react-parser";
import { useAuth } from "../logIn/AuthProvider";
import { useNavigate } from "react-router-dom";


function Payment() {
    const { t } = useTranslation();
  const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [Cancel, setCancel] = useState(false);
  const auth = useAuth();
  let [payment, setPayment] = useState([]);
  const { id } = useParams();
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCancelClose = () => setCancel(false);
    const handleCancelShow = () => setCancel(true);


  const getPayment = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/orders/variations/${id}`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            Authorization: "Bearer " + auth.token,
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      // setLabs(response.data.data);
      setPayment(response?.data?.data.variations[0].pivot);
      console.log(response?.data?.data.variations[0].pivot);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };

  const CancelOrder = async (data) => {
    try {
      const response = await fetch(
        `https://dentotools.addictaco.com/api/v1/orders/cancel/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        setCancel(false)
        return;
      }
      throw new Error(response.message);
    } catch (err) {
      setCancel(false)
      console.error(err);
    }
  };


  
  const [me, setMe] = useState();
  const getMe = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/clients/auth/profile`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            Authorization: "Bearer " + auth.token,
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setMe(response?.data?.data);
      console.log(response);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };

  const vatValue = Number(payment.vat) || 0; // Fallback to 0 if it's not a valid number
  const formattedVat = vatValue.toFixed(1); 
    
    useEffect(() => {
      getPayment();
      getMe();
        // document.body.classList.add('pro-nav');
        document.body.classList.add ('active-nav');
    }, []);

  return (
    <div className="main">
      <div className="layout-payment en">
        <div className="container">
          <div className="payment">
            <div className="row">
              <div className="col-md-6">
                <div className="card-payment">
                  <h2>User Details</h2>
                  <div className="d-flex justify-content-between mt-3 mb-3">
                    <h3>
                      <i className="fa fa-user"></i>
                      {me?.name}
                    </h3>
                    <h3>
                      <i className="fa fa-phone"></i> {me?.phone_number}
                    </h3>
                  </div>
                  {me?.user_type == "student" ? (
                    ""
                  ) : (
                    <p>
                      <i className="fa fa-location-dot"></i>
                      {me?.phone_number}
                    </p>
                  )}
                </div>
                {/* <div className="card-payment">
                  <h2>Payment</h2>
                  <Form className="form-contact p-0">
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Group
                          controlId="formStep1"
                          className="form-group"
                        >
                          <Form.Label>{t("Pay With:")}</Form.Label>
                          <div className="d-flex">
                            <Form.Check
                              label="Cash On delivery"
                              name="group1"
                              type="radio"
                              id="Cash"
                              checked
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                                            <Form.Group controlId="formStep1" className="form-group">
                                                <Form.Label>{t("Card_Number")}</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="Card_Number"
                                                    placeholder={t("Card_Number")}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group controlId="formStep1" className="form-group">
                                                <Form.Label>{t("Expiration Date")}</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="Expiration_Date"
                                                    placeholder={t("Expiration Date")}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group controlId="formStep1" className="form-group">
                                                <Form.Label>{t("CVV")}</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="CVV"
                                                    placeholder={t("CVV")}
                                                />
                                            </Form.Group>
                                        </div>
                    </div>
                  </Form>
                </div> */}
              </div>
              <div className="col-md-6">
                <div className="order-card">
                  <h2>Order Summary</h2>
                  {/* <h3>
                    Order Name <span>49.80 EGP</span>
                  </h3> */}
                  {/* <hr /> */}
                  <h3>
                    Price <span>{payment?.price} EGP</span>
                  </h3>
                  <h3>
                    Vat <span>{formattedVat} EGP</span>
                  </h3>
                  <h3>
                    Shipping Cost <span>{payment?.shipment_cost} EGP</span>
                  </h3>
                  <hr />
                  <h4>
                    <div>
                      <h3>Total</h3>
                    </div>
                    <span>{payment?.total} EGP</span>
                  </h4>
                  <div className="order-buttons">
                    {/* <button className="continue" onClick={handleShow}>
                      Pay {payment?.total} EGP
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="success">
              <img src={Img} alt="success" />
              <h2>The order was completed successfully</h2>
              <button className="continue" onClick={handleClose}>
                Check my Orders
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={Cancel} onHide={handleCancelClose}>
          <Modal.Header closeButton></Modal.Header>
          <div className="success">
            <h2>Are you want to cancel the order?</h2>
            <div className="d-flex w-100">
              <button className="back-order" onClick={handleCancelClose}>
                Back
              </button>
              <button className="cancel-order" onClick={CancelOrder}>
                Cancel order
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Payment;
