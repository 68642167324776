import React, { useEffect, useState } from "react";
// import Img1 from "../Assets/Images/Frame 1 (1).png";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import ser from "../Data/Ser";
import SUBSERVICES from "../Data/SUBSERVICES";
import Img8 from "../Assets/Images/Rectangle 29848.png";
import Img9 from "../Assets/Images/programmer-and-developer-teamwork-2022-02-10-20-35-17-utc 1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import Logo from "../Assets/Images/WhatsApp Image 2024-09-01 at 1.49.06 PM.jpeg";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import Partner from "./Partner";
import axios from "axios";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import Background from '../Assets/Images/b3707b6dfc.jpg';

function Services() {
  const { t, i18n } = useTranslation();

  // let [tab, setTab] = useState(1);
  let [services, setServices] = useState([]);
  let [subServices, setSubServices] = useState([]);
  const getServices = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/services`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setServices(response.data.data);
      
      if(response.data.data.length > 0 ) {
        getSubServices(response.data.data[0].id);
      }
      console.log(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };
  const getSubServices = async (id) => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/services/${id}/sub-services`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setSubServices(response.data.data);
      // console.log(tab);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };
  useEffect(() => {
    getServices();
    // getSubServices();
    document.body.classList.remove("join-nav");
    document.body.classList.add("active-nav");
  }, []);

  return (
    <div className="main">
      <div className="hero-services" style={{backgroundImage: `url(${Background})`}}><h1>{t("Service")}</h1></div>
      <div className="service">
        <div className="container">
          <Tabs>
            <TabList>
              <div className="row">
                {services?.map((x, index) => (
                  <Tab
                    className="col-lg-4 col-md-6 en"
                    key={index}
                    onClick={() => {
                      // setTab(x.id);
                      getSubServices(x.id);
                      console.log(x.id);
                    }}
                  >
                    <div className=" card-service">
                      <h2>{x.name}</h2>
                      <p>{parse(x.brief)}</p>
                    </div>
                  </Tab>
                ))}
              </div>
            </TabList>

                <h4>ALL SUBSERVES </h4>
            {services?.map((x, index) => (
              <>
                <TabPanel key={index}>
                  <div className="SUBSERVICES en">
                    <div className="container">
                      <div className="row">
                        {subServices?.map((x, index) => (
                          <Link
                            to={`/SUBSERVES/${x.id}`}
                            className="col-lg-4 col-md-6"
                            key={index}
                          >
                            <div className="card-SUBSERVICES en">
                              <div>
                                <img src={x.image} alt="lab" />
                                <span>{x.name}</span>
                              </div>
                              <p>{x.labs_count} Lab</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </>
            ))}
          </Tabs>
        </div>
      </div>
      <Partner />
      <div className="contact en">
        <div className="container">
          <div className="contact-card">
            <div className="contact-layout">
              <h2>
                For any questions or concerns <br /> don't hesitate to contact
                us.
              </h2>
              <Link to="/Contact" className="">
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
