import React, { useEffect, useLayoutEffect, useState } from "react";
import Img9 from "../Assets/Images/Basemap image (1).png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useAuth } from "../logIn/AuthProvider";
import axios from "axios";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import MasterForm from "../Steps/MasterForm";
import LocationMap from "./location";
import BottomBridge from "../Assets/Images/dento 1.png"
import NoBridge from "../Assets/Images/Screenshot 2024-10-22 173327.jpg"
import TopBridge from "../Assets/Images/dento 2.png"
import AllBridge from "../Assets/Images/9c3b9575-c7ac-4fda-bc12-68391403c44e-transformed.png"

function LabsDetails() {
  const [labs, setLabs] = useState(null);
  const [subOther, setSubOther] = useState([]);
  const [shade, setShade] = useState([]);
  const [subLabs, setSubLabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const { id } = useParams();
  const auth = useAuth();
  const [selectedBridge, setSelectedBridge] = useState("");

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow2(true);

  const [formData, setFormData] = useState({
    note: "",
    shade_id: [],
    bridge_id: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleBridgeChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setSelectedBridge(e.target.value);
  };




  const getImageByBridge = (bridgeId) => {
    switch (bridgeId) {
      case "1":
        return NoBridge;
      case "2":
        return NoBridge;
      case "3":
        return NoBridge;
      default:
        return NoBridge;
    }
  };

  // Fetch Lab details
  const getLabs = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/labs/${id}`,
        {
          headers: {
            Accept: "application/json",
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Content-Type": "application/json",
          },
        }
      );
      setLabs(response?.data?.data || {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };

  // Fetch SubLabs
  // const getSubLabs = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://dentotools.addictaco.com/api/v1/sub-services/${id}/labs`,
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     setSubLabs(response?.data?.data || []);
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: error.response.data.message,
  //       text: "Something went wrong!",
  //     });
  //   }
  // };
  
  const [bridge, setBridge] = useState();
  const [bridgeError, setBridgeError] = useState();
  const getBridge = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/orders/labs/${id}/bridges`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            Authorization: "Bearer " + auth.token,
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setBridge(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
      setBridgeError(error.response.data.message)
      // Swal.fire({
      //   icon: "error",
      //   title: error.response.data.message,
      //   text: "Something went wrong!",
      // });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const response = await axios
      .post(
        "https://dentotools.addictaco.com/api/v1/orders/order-bridge",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            Authorization: "Bearer " + auth.token,
            "device-type": "Web",
            "device-id": "123456",
          },
          processData: false,
          mimeType: "multipart/form-data",
          contentType: false,
          data: formData,
        }
      )
      .then((res) => {
        // navigate("/SignIn");
            navigate(`/Summary`, { state: { item: res.data.data } });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          text: "Something went wrong!",
        });
      });
  };
    
  
  const navigate = useNavigate();

  const getShade = async () => {
    try {
        const response = await axios.get(
            `https://dentotools.addictaco.com/api/v1/shades`,
            {
                headers: {
                    "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
                    "Accept-Language": "en",
                    "Content-Type": "application/json",
                },
            }
        );
        setShade(response?.data);
        console.log(response.data);
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: error.response.data.message,
            text: "Something went wrong!",
        });
    }
};

  // Fetch Other SubServices
  const getSubOther = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/sub-services/${id}/other`,
        {
          headers: {
            Accept: "application/json",
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Content-Type": "application/json",
          },
        }
      );
      setSubOther(response?.data?.data || []);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    getLabs();
    // getSubLabs();
    getSubOther();
    getBridge();
    getShade()
  }, [id]);

  if (loading) {
    return <div>Loading...</div>; // Show loading spinner or message
  }
  return (
    <div className="main">
      <div className="single-lab-details">
        <div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            modules={[Autoplay]}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            className="mySwiper"
          >
            {labs?.images?.length > 0 ? (
              labs.images.map((x, index) => (
                <SwiperSlide key={index}>
                  <div className="h-100">
                    <img
                      src={`${x}`}
                      alt="lab-logo"
                    />
                    <img src={Img9} className="img-layout" alt="lab-layout" />
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <p>No Images available</p>
            )}
          </Swiper>
        </div>
      </div>

      <div className="lab-details-logo en" key={labs?.id}>
        <div className="container">
          <div className="d-flex align-items-center">
            <img
              src={`${labs?.logo}`}
              alt="lab-logo"
            />
            <div>
              <ul>
                <li>
                  <i className="fa fa-star fa-yellow"></i>
                </li>
                {labs?.rate}
              </ul>
              <h2>{labs?.name}</h2>
              <span>{labs?.sub_services?.length} Services</span>
              <p>Delivery Time: {labs?.estimated_delivery_time}</p>
            </div>
          </div>
          <p>{parse(labs?.brief || "")}</p>
          <div className="order">
            {!auth.token ? (
              <Link to="/SignIn" className="profile-button">
                <i className="fa fa-user"></i>Login
              </Link>
            ) : (
              <>
                <button onClick={handleShow}>Order Teeth</button>
                {bridgeError == "No bridges found for this lab" ? "" : <button onClick={handleShow2}>Order Bridge</button>}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="SUBSERVICES en">
        <div className="container">
          <h2 className="main-heading en">SUB SERVICES </h2>
          <div className="row">
            {labs?.sub_services?.length > 0 ? (
              labs?.sub_services?.map((x, index) => (
                <Link to={`/SUBSERVES/${x.id}`} className="col-lg-4 col-md-6" key={index}>
                  <div className="card-SUBSERVICES en">
                    <img src={x.image} alt="lab" />
                    <span>{x.sub_service_name}</span>
                    {/* <p>{x.sub_services_count} Lab</p> */}
                  </div>
                </Link>
              ))
            ) : (
              <p>No SubLabs available</p>
            )}
          </div>
        </div>
      </div>

      <div className="SUBSERVICES en">
        <div className="container">
          <h2 className="main-heading en">Other SUBSERVICES </h2>
          <div className="row">
            {subOther.length > 0 ? (
              subOther.map((x, index) => (
                <Link to={`/SUBSERVES/${x.id}`} className="col-lg-4 col-md-6" key={index}>
                  <div className="card-SUBSERVICES en">
                    <img src={x.image} alt="lab" />
                    <span>{x.name}</span>
                    <p>{x.labs_count} Lab</p>
                  </div>
                </Link>
              ))
            ) : (
              <p>No Other SubServices available</p>
            )}
          </div>
        </div>
      </div>

      <LocationMap map={labs?.location_url} />

      <Modal show={show} size="lg" onHide={handleClose} className="crown-tabs">
        <Modal.Body>
          <h4>I Want to order ..</h4>
          <div className="container">
            <MasterForm lab={labs} />
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show2} size="lg" onHide={handleClose2} className="crown-tabs">
        <Modal.Body>
          <h4>I Want to order ..</h4>
          <div className="container">
            <form>
              <div className="row">
                <div className="col-md-12">
                {/* {selectedBridge && selectedBridge.length > 0 ( */}
                  <div className="image-container text-center">
                    <img
                      src={getImageByBridge(selectedBridge)}
                      alt={selectedBridge}
                      // style={{ display: selectedBridge ? "block" : "none" }}
                      className="w-50"
                    />
                  </div>
                {/* )} */}
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Bridge</label> 
                    <select
                      aria-label="Default select example"
                      name="bridge_id"
                      onChange={handleBridgeChange}
                      className="form-control"
                    >
                      <option>Select Bridge</option>
                      {bridge?.map((x, index) => (
                        <option value={x.id} key={index}>
                          {x.bridge} - Cost: {x.price}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group form-shade">
                    <label>Shade</label>
                    <select
                        aria-label="Default select example"
                        name="shade_id"
                        onChange={handleChange}
                        className="form-control"
                      >
                        <option>Select Shade</option>
                        {shade?.map((x, index) => (
                          <option value={x.id} key={index}>
                            {x.shade}
                          </option>
                        ))}
                      </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Note</label>
                    <textarea name="note" placeholder="Write Your Note" onChange={handleChange} className="form-control"></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <button className="Continue" type="submit" onClick={handleRegister}>
                    Check out
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LabsDetails;
