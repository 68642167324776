import React, {useState , useEffect} from "react";
import { NavLink, Link } from "react-router-dom";
import "../Assets/Css/Navbar.css";
import Logo from "../Assets/Images/Group.svg"
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useAuth } from "../logIn/AuthProvider";
import Swal from "sweetalert2";
import { axiosConfig } from "../logIn/axiosAPI";

function Navbar() {
  const auth = useAuth();

  const { t, i18n } = useTranslation();
  
  const [header, setHeader] = useState("header-home");
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header-home");
    } else if (window.scrollY > 270) {
      return setHeader("header-home2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  
  const [lang, setLang] = useState("");

  const detectLang = () => {
    if (localStorage.getItem("lang")) {
      setLang(localStorage.getItem("lang"));
      changeLayout(localStorage.getItem("lang"));
    } else {
      localStorage.setItem("lang", "en");
      setLang("en");
      changeLayout("en");
    }
    i18n.changeLanguage(lang);
  };

  const [value, setValue] = useState("en");
  const handleOnclick = (lang) => {
    const __lang = lang === "ar" ? "ar" : "en";
    i18n.changeLanguage(__lang);
    localStorage.setItem("lang", __lang);
    changeLayout(__lang);
    setLang(__lang);
    setValue(__lang);
  };

  const changeLayout = (lang) => {
    if (lang === "ar") {
      document.querySelectorAll(".en").forEach((el) => {
        el.classList.add("ar");
        el.classList.remove("en");
      });
    } else {
      document.querySelectorAll(".ar").forEach((el) => {
        el.classList.add("en");
        el.classList.remove("ar");
      });
    }
  };

  useEffect(() => {
    detectLang();
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <header className={`${header} navbar en`}>
      <div className="container">
        <div className="nav">
          <div className="nav-barnd">
            <img src={Logo} alt="logo" />
          </div>
          <div className="nav-links">
            <ul>
              <li className="">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("home")}
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/Services"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("Services")}
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/Lab"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("Lab")}
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/Freelancer"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("Freelancer")}
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/Sterilization"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  Sterilization
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/About"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("about_nav")}
                </NavLink>
              </li>
              <li className=" ">
                <NavLink
                  to="/Contact"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("contact")}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="nav-buttons">
            {!auth.token ? (
              <Link to="/SignIn" className="profile-button">
                <i className="fa fa-user"></i>Login
              </Link>
            ) : (
              <Link to="/Profile" className="profile-button">
                <i className="fa fa-user"></i>Profile
              </Link>
            )}
            {/* <select
              value={value}
              onChange={(e) => {
                // props?.changeLang?.();
                handleOnclick(lang === "en" ? "ar" : "en");
              }}
              className="nav-item dropdown language-select text-uppercase"
            >
              <option value="en" className="nav-item">
                <Link className="dropdown-item" to="#!">
                  En
                </Link>
              </option>
              <option value="ar" className="nav-item">
                <Link className="dropdown-item" to="#!">
                  Ar
                </Link>
              </option>
            </select> */}
            <a
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              aria-controls="offcanvasExample"
              className="bars"
            >
              <i className="fa fa-bars"></i>
            </a>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <div className="nav-barnd">
            <img src={Logo} alt="logo" />
          </div>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="nav-links">
            <ul>
              <li className="">
                <NavLink
                  to="/"
                  onClick={handleClose}
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("home")}
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/Services"
                  onClick={handleClose}
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("Services")}
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/Lab"
                  onClick={handleClose}
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("Lab")}
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/About"
                  onClick={handleClose}
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("about_nav")}
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/Freelancer"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("Freelancer")}
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/Sterilization"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  Sterilization
                </NavLink>
              </li>
              <li className=" ">
                <NavLink
                  to="/Contact"
                  onClick={handleClose}
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  {t("contact")}
                </NavLink>
              </li>
              <li>
                <select
                  value={value}
                  onChange={(e) => {
                    // props?.changeLang?.();
                    handleOnclick(lang === "en" ? "ar" : "en");
                  }}
                  className="nav-item dropdown language-select text-uppercase"
                >
                  <option value="en" className="nav-item">
                    <Link className="dropdown-item" to="#!">
                      En
                    </Link>
                  </option>
                  <option value="ar" className="nav-item">
                    <Link className="dropdown-item" to="#!">
                      Ar
                    </Link>
                  </option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
