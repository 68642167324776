import React , {useState,useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Lab from "./Components/Pages/Lab";
import Footer from "./Components/Footer/Footer";
import "./Components/translations/i18n";
import i18n from "i18next";
import "./Components/Assets/Css/Web.css"
import "./Components/Assets/Css/lang.css"
import Register from "./Components/logIn/Register";
import Student from "./Components/logIn/Student";
import SignIn from "./Components/logIn/SignIn";
import Clinic from "./Components/logIn/Clinic";
import Labs_details from "./Components/Pages/Labs_details";
import Services from "./Components/Pages/Services";
import Contact from "./Components/Pages/Contact";
import SUBSERVES from "./Components/Pages/SUBSERVES";
import Profile from "./Components/Pages/Profile";
import ScrollToTop from "./Components/Pages/ScrollToTop";
import Top from "./Components/Pages/backToTop";
import Payment from "./Components/Pages/Payment";
import Freelancer from "./Components/Pages/Freelancer";
import AuthProvider from "./Components/logIn/AuthProvider";
import PrivateRoute from "./Components/logIn/route";
import Freelance_details from "./Components/Pages/Freelance_details";
import Sterilization from "./Components/Pages/Sterilization";
import Terms from "./Components/Pages/Terms";
import Privacy from "./Components/Pages/Privacy";
import Summary from "./Components/Pages/summry";
import Bridge from "./Components/Pages/Bridge";

function App() {
  const [lang, setLang] = useState("");
  const detectLang = () => {
    if (localStorage.getItem("lang")) {
      setLang(localStorage.getItem("lang"));
      changeLayout(localStorage.getItem("lang"));
    } else {
      localStorage.setItem("lang", "en");
      setLang("en");
      changeLayout("en");
    }
    i18n.changeLanguage(lang);
  };

  const handleOnclick = (lang) => {
    const __lang = lang === "ar" ? "ar" : "en";
    i18n.changeLanguage(__lang);
    localStorage.setItem("lang", __lang);
    changeLayout(__lang);
    setLang(__lang);
  };

  const changeLayout = (lang) => {
    if (lang == "ar") {
      document.querySelectorAll(".en").forEach((el) => {
        el.classList.add("ar");
        el.classList.remove("en");
      });
    } else {
      document.querySelectorAll(".ar").forEach((el) => {
        el.classList.add("en");
        el.classList.remove("ar");
      });
    }
  };

  useEffect(() => {
    detectLang();
  }, []);

  return (
    <Router>
      <AuthProvider>
        <ScrollToTop />
        <Navbar changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")} />
        <Routes>
          <Route path="/Register" element={<Register />} />
          <Route element={<PrivateRoute />}>
            <Route
              path="/Login"
              element={<Register />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/"
              element={<Home />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Summary"
              element={<Summary />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Bridge"
              element={<Bridge />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Services"
              element={<Services />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Contact"
              element={<Contact />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/About"
              element={<About />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Lab"
              element={<Lab />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Sterilization"
              element={<Sterilization />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Freelancer"
              element={<Freelancer />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Payment/:id"
              element={<Payment />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Labs_details/:id"
              element={<Labs_details />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Freelance_details/:id"
              element={<Freelance_details />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Profile"
              element={<Profile />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/SUBSERVES/:id"
              element={<SUBSERVES />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/SignIn"
              element={<SignIn />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Clinic"
              element={<Clinic />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Student"
              element={<Student />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Terms"
              element={<Terms />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
            <Route
              path="/Privacy"
              element={<Privacy />}
              changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")}
            />
          </Route>
        </Routes>
        <Footer changeLang={() => handleOnclick(lang === "en" ? "ar" : "en")} />
        {/* <Top /> */}
      </AuthProvider>
    </Router>
  );
}

export default App;
